export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = "application/json";
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: { accessToken },
      } = store.getState();

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );
  axios.interceptors.response.use(
    (response: any) => response,
    (err: any) => {
      if (err.toString().includes("403")) {
        localStorage.removeItem("persist:crm");
        if (!window.location.pathname.includes("auth")) {
          window.location.reload();
        }
      }
    }
  );
}
