import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { MenuModel } from "../models/MenuModel";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  MenuRequested: "[Request Menu] Action",
  MenuLoaded: "[Load Menu] Menu API",
  SetMenu: "[Set Menu] Action",
};

const initialMenuState: IMenuState = {
  menu: undefined,
};

export interface IMenuState {
  menu?: MenuModel;
}

export const reducer = persistReducer(
  { storage, key: "crm-menu", whitelist: ["menu"] },
  (
    state: IMenuState = initialMenuState,
    action: ActionWithPayload<IMenuState>
  ) => {
    if (action.type === actionTypes.SetMenu) {
      const menu = action.payload?.menu;
      return { ...state, menu };
    } else {
      return state;
    }
  }
);

export const actions = {
  requestMenu: () => ({
    type: actionTypes.MenuRequested,
  }),
  setMenu: (menu: MenuModel) => ({
    type: actionTypes.SetMenu,
    payload: { menu },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.MenuRequested, function* menuRequested() {
    // @ts-ignore
    const { data: resp } = yield getUserByToken();
    yield put(actions.setMenu(resp.data));
  });
}
