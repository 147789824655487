/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useLayout } from "../core";

const Footer: FC = () => {
  const { classes } = useLayout();
  return (
    <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className="d-flex justify-content-center fs-6">
          <span className={"text-gray-600"}>
            {process.env.REACT_APP_COPYRIGHT_TEXT}
            <a
              href={process.env.REACT_APP_POWERED_BY_URL}
              target={"_blank"}
              rel={"noreferrer"}
            >
              {" "}
              {process.env.REACT_APP_POWERED_BY}
            </a>
          </span>
        </div>
        {/* end::Copyright */}
      </div>
      {/* end::Container */}
    </div>
  );
};

export { Footer };
