import { ResponseModel } from "app/models/ResponseModel";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const GET_DASHBOARD_URL = `${API_URL}/dashboard`;

class DashboardService {
  static fetchOrderTotals(date: any) {
    return axios.get<ResponseModel>(`${GET_DASHBOARD_URL}/order-totals`);
  }

  static fetchLastOrders(date: any) {
    return axios.get<ResponseModel>(`${GET_DASHBOARD_URL}/last-orders`);
  }
}

export default DashboardService;
