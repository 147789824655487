/* eslint-disable react/jsx-no-target-blank */
import { MenuCategoryModel } from "app/modules/menu/models/MenuCategoryModel";
import { MenuModel } from "app/modules/menu/models/MenuModel";
import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "setup";
import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuSuralAdmin() {
  const menu: Array<MenuModel> = useSelector<RootState>(
    ({ menu }) => menu.menu,
    shallowEqual
  ) as MenuModel[];

  const [categories, setCategories] = useState<Array<MenuCategoryModel>>([]);

  useEffect(() => {
    let cats: MenuCategoryModel[] = [];
    if (menu && menu.length > 0) {
      menu.forEach((m) => {
        if (cats.filter((cat) => cat.id === m.menuCategory.id).length < 1) {
          cats.push(m.menuCategory);
        }
      });

      setCategories(cats);
    }
  }, []);

  return (
    <>
      <div>
        <div className="menu-item">
          <div className="menu-content pb-2">
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
              SİPARİŞ YÖNETİMİ
            </span>
          </div>
        </div>
        <AsideMenuItem
          to={"/orders"}
          title={"Siparişler"}
          fontIcon={"fa-list"}
        />
        <AsideMenuItem
          to={"/orders/add"}
          title={"Yeni Sipariş Oluştur"}
          fontIcon={"fa-plus"}
        />
        <div className="menu-item">
          <div className="menu-content pb-2">
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
              GENEL İŞLEMLER
            </span>
          </div>
        </div>
        <AsideMenuItem
          to={"/zones"}
          title={"Bölgeler"}
          fontIcon={"fa-search-location"}
        />
        <AsideMenuItem
          to={"/products"}
          title={"Ürünler"}
          fontIcon={"fa-tint"}
        />
        <AsideMenuItem
          to={"/product-prices"}
          title={"Ücretlendirme"}
          fontIcon={"fa-lira-sign"}
        />
        <AsideMenuItem
          to={"/categories"}
          title={"Ürün Kategorileri"}
          fontIcon={"fa-list"}
        />
        <AsideMenuItem
          to={"/customers"}
          title={"Müşteriler"}
          fontIcon={"fa-user-tie"}
        />
        <div className="menu-item">
          <div className="menu-content pb-2">
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
              TANIMLAR
            </span>
          </div>
        </div>
        <AsideMenuItem
          to={"/campaigns"}
          title={"Kampanyalar"}
          fontIcon={"fa-bullhorn"}
        />
        {/*<AsideMenuItem
          to={"#"}
          title={"Dağıtıcılar"}
          fontIcon={"fa-truck-loading"}
        />*/}
        <AsideMenuItem
          to={"/order-channels"}
          title={"Sipariş Kanalları"}
          fontIcon={"fa-shopping-cart"}
        />
        <AsideMenuItem
          to={"/payment-types"}
          title={"Ödeme Tipleri"}
          fontIcon={"fa-credit-card"}
        />
        {/*   <AsideMenuItem
          to={"/times"}
          title={"Giriş-Çıkış"}
          fontIcon={"fa-bullhorn"}
        /> */}
        <AsideMenuItem
          to={"/complaints"}
          title={"Öneri/Şikayet"}
          fontIcon={"fa-exclamation-circle"}
        />
        <AsideMenuItem
          to={"/cancel-notes"}
          title={"İptal Notları"}
          fontIcon={"fa-comments"}
        />
        {/* <AsideMenuItem to={"/sss"} title={"SSS"} fontIcon={"fa-bullhorn"} /> */}
        <AsideMenuItem
          to={"/users"}
          title={"Kullanıcılar"}
          fontIcon={"fa-users"}
        />

        {/*<div className="menu-item">
          <div className="menu-content pb-2">
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
              FİNANS YÖNETİMİ
            </span>
          </div>
        </div>
        <AsideMenuItem to={"#"} title={"İrsaliye"} fontIcon={"fa-receipt"} />
        <AsideMenuItem to={"#"} title={"Fatura"} fontIcon={"fa-file-invoice"} />
        <AsideMenuItem to={"#"} title={"Stok Bilgileri"} fontIcon={"fa-box"} />*/}
        <div className="menu-item">
          <div className="menu-content pb-2">
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
              LOKASYON YÖNETİMİ
            </span>
          </div>
        </div>
        <AsideMenuItem
          to={"/cities"}
          title={"İller"}
          fontIcon={"fa-map-marker-alt"}
        />
        <AsideMenuItem
          to={"/districts"}
          title={"İlçeler"}
          fontIcon={"fa-map-pin"}
        />
        <AsideMenuItem
          to={"/neighbourhoods"}
          title={"Mahalle"}
          fontIcon={"fa-map-marked"}
        />
        <AsideMenuItem
          to={"/streets"}
          title={"Sokaklar"}
          fontIcon={"fa-street-view"}
        />
        <div className="menu-item">
          <div className="menu-content pb-2">
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
              RAPORLAR
            </span>
          </div>
        </div>
        <AsideMenuItem
          to={"/order-reports"}
          title={"Sipariş Raporu"}
          fontIcon={"fa-chart-bar"}
        />
        <AsideMenuItem
          to={"/customer-reports"}
          title={"Müşteri Raporu"}
          fontIcon={"fa-user-clock"}
        />
        <AsideMenuItem
          to={"/distributor-reports"}
          title={"Dağıtıcı Raporu"}
          fontIcon={"fa-truck-loading"}
        />
      </div>
    </>
  );
}
