/* eslint-disable react/jsx-no-target-blank */
import { MenuCategoryModel } from "app/modules/menu/models/MenuCategoryModel";
import { MenuModel } from "app/modules/menu/models/MenuModel";
import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "setup";
import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuSuralDistributor() {
  const menu: Array<MenuModel> = useSelector<RootState>(
    ({ menu }) => menu.menu,
    shallowEqual
  ) as MenuModel[];

  const [categories, setCategories] = useState<Array<MenuCategoryModel>>([]);

  useEffect(() => {
    let cats: MenuCategoryModel[] = [];
    if (menu && menu.length > 0) {
      menu.forEach((m) => {
        if (cats.filter((cat) => cat.id === m.menuCategory.id).length < 1) {
          cats.push(m.menuCategory);
        }
      });

      setCategories(cats);
    }
  }, []);

  return (
    <>
      <div>
        <div className="menu-item">
          <div className="menu-content pb-2">
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
              İŞLEMLER
            </span>
          </div>
        </div>
        <AsideMenuItem
          to={"/orders"}
          title={"Siparişlerim"}
          fontIcon={"fa-list"}
        />

        <AsideMenuItem
          to={"/customers"}
          title={"Müşteri Listesi"}
          fontIcon={"fa-user-tie"}
        />

        <AsideMenuItem
          to={"/complaints"}
          title={"Öneri/Şikayet"}
          fontIcon={"fa-exclamation-circle"}
        />

        <div className="menu-item">
          <div className="menu-content pb-2">
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
              RAPORLAR
            </span>
          </div>
        </div>
        <AsideMenuItem
          to={"/order-reports"}
          title={"Sipariş Raporu"}
          fontIcon={"fa-chart-bar"}
        />
        <AsideMenuItem
          to={"/customer-reports"}
          title={"Müşteri Raporu"}
          fontIcon={"fa-user-clock"}
        />
      </div>
    </>
  );
}
