/* eslint-disable react/jsx-no-target-blank */
import { MenuCategoryModel } from "app/modules/menu/models/MenuCategoryModel";
import { MenuModel } from "app/modules/menu/models/MenuModel";
import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "setup";
import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuPerson() {
  const menu: Array<MenuModel> = useSelector<RootState>(
    ({ menu }) => menu.menu,
    shallowEqual
  ) as MenuModel[];

  const [categories, setCategories] = useState<Array<MenuCategoryModel>>([]);

  useEffect(() => {
    let cats: MenuCategoryModel[] = [];
    if (menu && menu.length > 0) {
      menu.forEach((m) => {
        if (cats.filter((cat) => cat.id === m.menuCategory.id).length < 1) {
          cats.push(m.menuCategory);
        }
      });

      setCategories(cats);
    }
  }, []);

  return (
    <>
      <div>
        <div className="menu-item">
          <div className="menu-content pb-2">
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
              SİPARİŞ YÖNETİMİ
            </span>
          </div>
        </div>
        <AsideMenuItem
          to={"/orders"}
          title={"Siparişler"}
          fontIcon={"fa-list"}
        />
        <AsideMenuItem
          to={"/orders/add"}
          title={"Yeni Sipariş Oluştur"}
          fontIcon={"fa-plus"}
        />
        <div className="menu-item">
          <div className="menu-content pb-2">
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">
              GENEL İŞLEMLER
            </span>
          </div>
        </div>
        <AsideMenuItem
          to={"/zones"}
          title={"Bölgeler"}
          fontIcon={"fa-search-location"}
        />
        <AsideMenuItem
          to={"/products"}
          title={"Ürünler"}
          fontIcon={"fa-tint"}
        />
        <AsideMenuItem
          to={"/product-prices"}
          title={"Ücretlendirme"}
          fontIcon={"fa-lira-sign"}
        />
        <AsideMenuItem
          to={"/customers"}
          title={"Müşteriler"}
          fontIcon={"fa-user-tie"}
        />
      </div>
    </>
  );
}
