/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

type Props = {
  onChange: any;
};
const OrderTotalListFilter: React.FC<Props> = ({ onChange }) => {
  const [selectedType, setSelectedType] = useState("alltime");
  return (
    <div
      className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
      data-kt-menu="true"
    >
      <div className="px-7 py-5">
        <div className="fs-5 text-dark fw-bolder">Filtrele</div>
      </div>

      <div className="separator border-gray-200"></div>

      <div className="px-7 py-5">
        <div className="mb-10">
          <label className="form-label fw-bold">Tarih Aralığı:</label>

          <div>
            <select
              className="form-select form-select-solid"
              data-kt-select2="true"
              data-placeholder="Select option"
              data-allow-clear="true"
              onChange={(v) => {
                setSelectedType(v.target.value);
              }}
              value={selectedType}
            >
              <option value="today">Bugün</option>
              <option value="yesterday">Dün</option>
              <option value="tweek">Bu Hafta</option>
              <option value="lweek">Geçen Hafta</option>
              <option value="tmonth">Bu Ay</option>
              <option value="lmonth">Geçen Ay</option>
              <option value="alltime">Tüm Zamanlar</option>
            </select>
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <button
            type="reset"
            className="btn btn-sm btn-white btn-active-light-primary me-2"
            data-kt-menu-dismiss="true"
            onClick={() => {
              setSelectedType("alltime");
              onChange("alltime");
            }}
          >
            Sıfırla
          </button>

          <button
            type="button"
            onClick={() => onChange(selectedType)}
            className="btn btn-sm btn-primary"
            data-kt-menu-dismiss="true"
          >
            Uygula
          </button>
        </div>
      </div>
    </div>
  );
};
export default OrderTotalListFilter;
