/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Link, Routes, Outlet } from "react-router-dom";
import { Error500 } from "./components/Error500";
import { Error404 } from "./components/Error404";
import { toAbsoluteUrl } from "../../../crm/helpers";
import { useIntl } from "react-intl";
const {
  REACT_APP_POWERED_BY,
  REACT_APP_POWERED_BY_URL,
  REACT_APP_COPYRIGHT_TEXT,
} = process.env;
const ErrorsLayout = () => {
  const intl = useIntl();
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
        style={{
          backgroundImage: `url('${toAbsoluteUrl(
            "/media/illustrations/progress-hd.png"
          )}')`,
        }}
      >
        <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-20">
          <a href="/dashboard" className="mb-10 pt-lg-20">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/crm-logo.png")}
              className="h-60px mb-5"
            />
          </a>
          <div
            className="
          d-flex
          flex-row-auto
          bgi-no-repeat
          bgi-position-x-center
          bgi-size-contain
          bgi-position-y-bottom
          mt-10
          min-h-100px min-h-lg-250px mb-10 mb-lg-0
        "
            style={{
              backgroundImage: `url('${toAbsoluteUrl(
                "/media/svg/illustrations/404.svg"
              )}')`,
            }}
          ></div>
          <div className="pt-lg-10 mb-10">
            <Outlet />
            <div className="text-center">
              <Link to="/" className="btn btn-lg btn-primary fw-bolder">
                <i className={"bi bi-chevron-left me-2 text-white"}></i>
                {intl.formatMessage({ id: "TURN_BACK" })}
              </Link>
            </div>
          </div>
        </div>

        {/* begin::Footer */}
        <div className="d-flex flex-center flex-column-auto p-10">
          <div className="d-flex align-items-center  fs-6">
            <span className={"text-gray-600"}>
              {REACT_APP_COPYRIGHT_TEXT}
              <a
                href={REACT_APP_POWERED_BY_URL}
                target={"_blank"}
                rel={"noreferrer"}
              >
                {" "}
                {REACT_APP_POWERED_BY}
              </a>
            </span>
          </div>
        </div>
        {/* end::Footer */}
      </div>
    </div>
  );
};

const ErrorsPage = () => (
  <Routes>
    <Route element={<ErrorsLayout />}>
      <Route path="404" element={<Error404 />} />
      <Route path="500" element={<Error500 />} />
      <Route index element={<Error404 />} />
    </Route>
  </Routes>
);

export { ErrorsPage };
