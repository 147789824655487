/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { getCSSVariableValue } from "../../../../assets/ts/_utils";
import { KTSVG } from "../../../../helpers";
import OrderTotalListFilter from "../../../content/dropdown/custom-filters/Dropdown1";
import moment from "moment";
import "moment/locale/tr";
import StringHelper from "../../../../helpers/StringHelper";

type Props = {
  className: string;
  chartColor: string;
  chartHeight: string;
};

const TotalOrdersMixedWidget: React.FC<Props> = ({
  className,
  chartColor,
  chartHeight,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  const [chartLabels, setChartLabels] = useState([
    "Oca",
    "Şub",
    "Mar",
    "Nis",
    "May",
    "Haz",
    "Tem",
    "Ağu",
  ]);

  const [chartData, setChartData] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [activeDate, setActiveDate] = useState("alltime");
  const [activeChart, setActiveChart] = useState<ApexCharts>();

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    createChart();
  }, [chartRef]);

  const createChart = (labels?: any, data?: any) => {
    if (activeChart) {
      activeChart!.destroy();
    }
    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(
        chartColor,
        chartHeight,
        data ?? chartData,
        labels ?? chartLabels
      )
    );
    if (chart) {
      chart.render();
    }

    setActiveChart(chart);

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  };

  const changeDateData = (type: any) => {
    setActiveDate(type);
    let label = [""];
    let data = [0];
    if (type === "today") {
      label = [moment().format("ddd").toString()];
      data = [0];
    } else if (type === "yesterday") {
      label = [moment().subtract(1, "days").format("ddd")];
      data = [0];
      console.log(label);
    } else if (type === "lweek") {
      label = moment.weekdaysShort();
      let items: any = [];
      moment.weekdaysShort().forEach((a: any) => items.push(0));
      data = items;
    } else if (type === "lmonth") {
      label = [moment().subtract(1, "months").format("MMM")];
      data = [0];
    } else if (type === "alltime") {
      label = [moment().format("ddd").toString()];
      data = [0];
    }

    setChartLabels(label);
    setChartData(data);
    createChart(label, data);
  };
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className="card-body p-0 d-flex justify-content-between flex-column overflow-hidden">
        {/* begin::Hidden */}
        <div className="d-flex align-items-start flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3">
          <div className="me-2">
            <span className="fw-bolder text-gray-800 d-block fs-3">
              Sipariş Tutarı
            </span>
            <span className="text-gray-400 fw-bold">
              {StringHelper.dateFilterName(activeDate)}
            </span>
            <div className={`fw-bolder pt-5 fs-3 text-${chartColor}`}>₺0</div>
          </div>
          <div className="card-toolbar">
            <button
              type="button"
              className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
              data-kt-menu-flip="top-end"
            >
              <KTSVG
                path="/media/icons/duotune/general/gen024.svg"
                className="svg-icon-2"
              />
            </button>
            <OrderTotalListFilter onChange={(v: string) => changeDateData(v)} />
          </div>
        </div>

        {/* end::Hidden */}

        {/* begin::Chart */}
        <div ref={chartRef} className="mixed-widget-10-chart"></div>
        {/* end::Chart */}
      </div>
    </div>
  );
};

const chartOptions = (
  chartColor: string,
  chartHeight: string,
  chartData: any,
  chartLabel: any
): ApexOptions => {
  const labelColor = getCSSVariableValue("--bs-gray-500");
  const borderColor = getCSSVariableValue("--bs-gray-200");
  const secondaryColor = getCSSVariableValue("--bs-gray-300");
  const baseColor = getCSSVariableValue("--bs-" + chartColor);

  return {
    series: [
      {
        name: "Kazanç",
        data: chartData,
      },
    ],
    chart: {
      fontFamily: "inherit",
      type: "bar",
      height: chartHeight,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: chartLabel,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    fill: {
      type: "solid",
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function (val) {
          return "₺" + val;
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      padding: {
        top: 10,
      },
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };
};

export { TotalOrdersMixedWidget };
