import { IOption } from "../../app/models/IOption";
import { ICity } from "../../app/models/ICity";
import moment from "moment";

class StringHelper {
  static convertCustomerType(type: string) {
    switch (type) {
      case "invidual":
        return "Bireysel";
      case "corporate":
        return "Kurumsal";
      default:
        break;
    }
  }

  static phoneExport(phoneNumbers: []) {
    return phoneNumbers.join(",");
  }

  static moneyFormat(value: any) {
    return new Intl.NumberFormat("tr-TR", {
      maximumSignificantDigits: 3,
      style: "currency",
      currency: "TRY",
    }).format(value);
  }

  static complaintName(val: string) {
    return val.replaceAll("oneri", "Öneri").replaceAll("sikayet", "Şikayet");
  }

  static insertSelectOptions(
    resp: any,
    setFunc: any,
    insertDefault: boolean = true
  ) {
    var items = new Array<IOption>();
    if (insertDefault) items.push({ value: 0, label: "Seçiniz" });
    resp.data.data.map((item: ICity) => {
      return items.push({ value: item.id, label: item.name });
    });
    setFunc(items);
  }

  static orders(roleId: any = 1, status: any = "") {
    let statusList = [
      { value: "waiting", label: "Onay Bekliyor" },
      { value: "getting_ready", label: "Hazırlanıyor" },
      // { value: "on_the_way", label: "Yolda" },
      { value: "delivery", label: "Teslim Edildi" },
      { value: "cancelled", label: "İptal Edildi" },
      { value: "refunded", label: "İade Edildi" },
    ];

    if (roleId && roleId !== 1) {
      if (status && status === "delivery") {
        statusList = [{ value: "delivery", label: "Teslim Edildi" }];
      } else {
        statusList.splice(4, 1);
        statusList.splice(4, 1);
      }
    }

    return statusList;
  }

  static orderStatus(status: string) {
    switch (status) {
      case "waiting":
        return "Onay Bekliyor";
      case "getting_ready":
        return "Hazırlanıyor";
      case "on_the_way":
        return "Yolda";
      case "delivery":
        return "Teslim Edildi";
      case "cancelled":
        return "İptal Edildi";
      case "refunded":
        return "İade Edildi";
      default:
        break;
    }
  }

  static orderStatusBadge(status: string) {
    switch (status) {
      case "waiting":
        return "badge-warning";
      case "getting_ready":
        return "badge-secondary";
      case "on_the_way":
        return "badge-info";
      case "delivery":
        return "badge-success";
      case "cancelled":
        return "badge-danger";
      case "refunded":
        return "badge-warning";
      default:
        break;
    }
  }

  static dateFilterName(type: string) {
    switch (type) {
      case "alltime":
        return "Tüm Zamanlar";
      case "tmonth":
        return "Bu Ay";
      case "today":
        return "Bugün";
      case "yesterday":
        return "Dün";
      case "lmonth":
        return "Geçen Ay";
      case "lweek":
        return "Geçen Hafta";
      case "tweek":
        return "Bu Hafta";
      default:
        break;
    }
  }

  static showMinuteDiff(
    date: any,
    className: string = "info",
    useDate: any = Date.now()
  ) {
    let a = moment(useDate);
    let b = moment(date);
    let minute = a.diff(b, "minute");
    let desc = "";
    if (minute < 60) {
      desc = minute + " dakika";
    } else {
      if (minute >= 1440) {
        minute = a.diff(b, "day");
        desc = minute + " gün";
      } else {
        minute = a.diff(b, "hour");
        desc = minute + " saat";
      }
      className = "danger";
    }
    return `<span class="mt-2 badge badge-${className}">${desc}</span>`;
  }
}
export default StringHelper;
