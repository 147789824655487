/* eslint-disable jsx-a11y/anchor-is-valid */
import { OrderTotalListWidget } from "crm/partials/widgets/custom/OrderTotalListWidget";
import { TotalCountOrdersMixedWidget } from "crm/partials/widgets/mixed/custom-mixed/TotalCountOrdersMixedWidget";
import { TotalOrdersMixedWidget } from "crm/partials/widgets/mixed/custom-mixed/TotalOrdersMixedWidget";
import { LatestOrdersTableWidget } from "crm/partials/widgets/tables/custom-tables/LatestOrdersTableWidget";
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../crm/layout/core";
import { IDashboardOrderTotal } from "../../models/IDashboardOrderTotal";
import DashboardService from "../../services/DashboardService";
import { IApiResponse } from "../../models/IApiResponse";
import { IDashboardLastOrder } from "../../models/IDashboardLastOrder";

type Props = {
  data: IDashboardOrderTotal[];
  lastOrders: IDashboardLastOrder[];
};
const DashboardPage: FC<Props> = (props: Props) => (
  <>
    {/* begin::Row */}
    <div className="row gy-5 g-xl-8">
      <div className="col-xxl-4">
        <OrderTotalListWidget data={props.data} className="card-xxl-stretch" />
      </div>
      <div className="col-xxl-8">
        <LatestOrdersTableWidget
          data={props.lastOrders}
          className="card-xxl-stretch mb-5 mb-xl-8"
        />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className="row gy-5 gx-xl-8">
      <div className="col-xl-6">
        <TotalOrdersMixedWidget
          className="card-xxl-stretch-100 mb-5 mb-xl-8"
          chartColor="primary"
          chartHeight="375px"
        />
      </div>
      <div className="col-xl-6">
        <TotalCountOrdersMixedWidget
          className="card-xxl-stretch-100 mb-5 mb-xl-8"
          chartColor="danger"
          chartHeight="375px"
        />
      </div>
    </div>
    {/* end::Row */}
  </>
);

const DashboardWrapper: FC = () => {
  const intl = useIntl();

  const [dataItems, setDataItems] = useState<IDashboardOrderTotal[]>([]);
  const [lastOrders, setLastOrders] = useState<IDashboardLastOrder[]>([]);

  useEffect(() => {
    fetchOrderTotals();
  }, []);

  const fetchOrderTotals = async () => {
    await DashboardService.fetchOrderTotals("").then((resp: any) => {
      if (resp.data != null) {
        let items: IDashboardOrderTotal[] = [];
        const data = resp.data.data as IApiResponse[];
        data.map((item: any) => {
          let i = item as IDashboardOrderTotal;
          items.push(i);
        });
        setDataItems(items);
      }
    });

    await DashboardService.fetchLastOrders("").then((resp: any) => {
      if (resp.data != null) {
        let items: IDashboardLastOrder[] = [];
        const data = resp.data.data as IApiResponse[];
        data.map((item: any) => {
          let i = item as IDashboardLastOrder;
          items.push(i);
        });
        setLastOrders(items);
      }
    });
  };

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.STATISTICS" })}
      </PageTitle>
      <DashboardPage lastOrders={lastOrders} data={dataItems} />
    </>
  );
};

export { DashboardWrapper };
