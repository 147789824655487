/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as auth from "../redux/AuthRedux";
import { login } from "../redux/AuthCRUD";
import { KTSVG } from "../../../../crm/helpers";

const loginSchema = Yup.object().shape({
  userName: Yup.string()
    .required("Kullanıcı adınızı girmediniz"),
  password: Yup.string().required("Şifrenizi girmediniz"),
});

const initialValues = {
  userName: "",
  password: "",
};

export function Login() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        login(values.userName, values.password)
          .then(({ data: { data } }) => {
            setLoading(false);
            dispatch(auth.actions.login(data));
          })
          .catch(() => {
            setLoading(false);
            setSubmitting(false);
            setStatus("Giriş bilgileriniz geçersiz");
          });
      }, 1000);
    },
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {/* begin::Heading */}
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">Giriş Yapın</h1>
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger d-flex align-items-center p-5 mb-10">
          <span className="svg-icon svg-icon-2hx svg-icon-danger me-3">
            <KTSVG
              path="/media/icons/duotune/general/gen044.svg"
              className="svg-icon-muted svg-icon-2hx svg-icon-danger"
            />
          </span>

          <div className="d-flex flex-column">
            <h5 className="mb-1">Hata</h5>
            <span>{formik.status}</span>
          </div>
        </div>
      )}

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <label className="form-label fs-6 fw-bolder text-dark">Kullanıcı Adı</label>
        <input
          placeholder=""
          {...formik.getFieldProps("userName")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.userName && formik.errors.userName },
            {
              "is-valid": formik.touched.userName && !formik.errors.userName,
            }
          )}
          type="userName"
          name="userName"
          autoComplete="off"
        />
        {formik.touched.userName && formik.errors.userName && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.userName}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            {/* begin::Label */}
            <label className="form-label fw-bolder text-dark fs-6 mb-0">
              Şifre
            </label>
            {/* end::Label */}
          </div>
        </div>
        <input
          type="password"
          autoComplete="off"
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid": formik.touched.password && formik.errors.password,
            },
            {
              "is-valid": formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Devam</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Giriş yapılıyor...
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          )}
        </button>
        {/* begin::Link */}
        <Link
          to="/auth/forgot-password"
          className="link-primary fs-6 fw-bolder"
          style={{ marginLeft: "5px" }}
        >
          Şifremi unuttum?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Action */}
    </form>
  );
}
