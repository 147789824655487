/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTSVG } from "../../../helpers";
import { Dropdown1 } from "../../content/dropdown/Dropdown1";
import { IDashboardOrderTotal } from "../../../../app/models/IDashboardOrderTotal";
import OrderTotalListFilter from "../../content/dropdown/custom-filters/Dropdown1";
import { Link } from "react-router-dom";

type Props = {
  className: string;
  data: IDashboardOrderTotal[];
};

const OrderTotalListWidget: React.FC<Props> = ({ className, data }) => {
  return (
    <div className="card card-xl-stretch mb-5 mb-xl-8">
      {/* begin::Header */}
      <div className="card-header border-0">
        <h3 className="card-title fw-bolder text-dark">
          Sipariş İstatistikleri
        </h3>
        <div className="card-toolbar">
          <button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <KTSVG
              path="/media/icons/duotune/general/gen024.svg"
              className="svg-icon-2"
            />
          </button>
          <OrderTotalListFilter onChange={() => console.log("a")} />
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body pt-0">
        {/* begin::Item */}
        {data &&
          data.map((itm: IDashboardOrderTotal) => {
            return (
              <div
                className={`d-flex align-items-center bg-light-${itm.colorClass} rounded p-5 mb-7`}
              >
                {/* begin::Icon */}
                <span className={`svg-icon svg-icon-${itm.colorClass} me-5`}>
                  <KTSVG
                    path="/media/icons/duotune/abstract/abs027.svg"
                    className="svg-icon-1"
                  />
                </span>
                {/* end::Icon */}
                {/* begin::Title */}
                <div className="flex-grow-1 me-2">
                  <Link to={itm.code ? `/orders?type=${itm.code}` : "/orders"}>
                    <a
                      className={`fw-bolder text-gray-800 text-hover-${itm.colorClass} fs-6`}
                    >
                      {itm.type}
                    </a>
                  </Link>
                  <span className="text-muted fw-bold d-block"></span>
                </div>
                {/* end::Title */}
                {/* begin::Lable */}
                <span className={`fw-bolder text-${itm.colorClass} py-1`}>
                  {itm.total}
                </span>
                {/* end::Lable */}
              </div>
            );
          })}
        {/* end::Item */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { OrderTotalListWidget };
