/* eslint-disable react/jsx-no-target-blank */
import { IUser } from "app/modules/auth/models/UserModel";
import { MenuCategoryModel } from "app/modules/menu/models/MenuCategoryModel";
import { MenuModel } from "app/modules/menu/models/MenuModel";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "setup";
import { AsideMenuItem } from "./AsideMenuItem";
import { AsideMenuPerson } from "./AsideMenuPerson";
import { AsideMenuSuralAdmin } from "./AsideMenuSuralAdmin";
import { AsideMenuSuralDistributor } from "./AsideMenuSuralDistributor";

export function AsideMenuSural() {
  const menu: Array<MenuModel> = useSelector<RootState>(
    ({ menu }) => menu.menu,
    shallowEqual
  ) as MenuModel[];

  const [categories, setCategories] = useState<Array<MenuCategoryModel>>([]);
  const user: IUser = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as IUser;

  useEffect(() => {
    let cats: MenuCategoryModel[] = [];
    if (menu && menu.length > 0) {
      menu.forEach((m) => {
        if (cats.filter((cat) => cat.id === m.menuCategory.id).length < 1) {
          cats.push(m.menuCategory);
        }
      });

      setCategories(cats);
    }
  }, []);

  return (
    <>
      <AsideMenuItem
        to={"/dashboard"}
        title={"Ana Sayfa"}
        fontIcon={"fa-home"}
      />
      <div>
        {user.roleId === 2 && <AsideMenuSuralDistributor />}
        {user.roleId === 1 && <AsideMenuSuralAdmin />}
        {user.roleId === 3 && <AsideMenuPerson />}
      </div>
    </>
  );
}
