/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IUser } from "../../../../app/modules/auth/models/UserModel";
import * as auth from "../../../../app/modules/auth/redux/AuthRedux";
import { RootState } from "../../../../setup";
import { toAbsoluteUrl } from "../../../helpers";

const HeaderCustomUserMenu: FC = () => {
  const user: IUser = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as IUser;

  const dispatch = useDispatch();
  const logout = () => {
    dispatch(auth.actions.logout());
  };

  const intl = useIntl();
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img alt="Logo" src={toAbsoluteUrl("/media/avatars/blank.png")} />
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {user.companyName ? user.companyName : user.fullName}
            </div>
            <span className="badge badge-light-success fw-bolder fs-8 px-2 py-2 mt-1">
              {user.roleName}
            </span>
            {/*  <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
              {user.email}
            </a> */}
          </div>
        </div>
      </div>

      <div className="separator my-2" />

      <div className="menu-item px-5 my-1">
        <Link to={`/users/${user.id}`} className="menu-link px-5">
          <em className="fas fa-cog me-3" />
          {intl.formatMessage({ id: "ACCOUNT_SETTINGS" })}
        </Link>
      </div>

      {/* <div className="menu-item px-5 my-1">
        <Link to="/account/password" className="menu-link px-5">
          <em className="fas fa-key me-3" />
          Şifre Değiştir
        </Link>
      </div> */}

      <div className="menu-item px-5">
        <a onClick={logout} className="menu-link px-5">
          <em className="fas fa-sign-out-alt me-3" />
          {intl.formatMessage({ id: "LOGOUT" })}
        </a>
      </div>
    </div>
  );
};

export { HeaderCustomUserMenu };
