import ReactDOM from "react-dom";
// Redux
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import * as _redux from "./setup";
import store, { persistor } from "./setup/redux/Store";
// Axios
import axios from "axios";
import { Chart, registerables } from "chart.js";

// Apps
import { MetronicI18nProvider } from "./crm/i18n/Metronici18n";

import "./crm/assets/sass/style.scss";
import "./crm/assets/sass/plugins.scss";
import "./crm/assets/sass/style.react.scss";
import { AppRoutes } from "./app/routing/AppRoutes";

_redux.setupAxios(axios, store);

Chart.register(...registerables);

ReactDOM.render(
  <MetronicI18nProvider>
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<div>Yükleniyor...</div>}>
        <AppRoutes />
      </PersistGate>
    </Provider>
  </MetronicI18nProvider>,
  document.getElementById("root")
);
