import { ResponseModel } from 'app/models/ResponseModel'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export const GET_MENU_LIST_URL = `${API_URL}/menu`

export function getMenuList() {
  return axios.get<ResponseModel>(GET_MENU_LIST_URL)
}
