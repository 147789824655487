import { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../crm/assets/ts/_utils";
import { MasterLayout } from "../../crm/layout/MasterLayout";
import { DashboardWrapper } from "../modules/dashboard/DashboardWrapper";

const PrivateRoutes = () => {
  const UsersPage = lazy(() => import("../modules/users/UsersPage"));
  const ZoneList = lazy(() => import("../modules/zones/ZonesPage"));
  const ProductsPage = lazy(() => import("../modules/products/ProductsPage"));
  const PersonsPage = lazy(() => import("../modules/persons/PersonsPage"));
  const OrdersPage = lazy(() => import("../modules/orders/OrdersPage"));
  const CitiesPage = lazy(() => import("../modules/cities/CitiesPage"));
  const NeighbourHoodsPage = lazy(
    () => import("../modules/neighbourhoods/NeighbourHoodsPage")
  );
  const StreetsPage = lazy(() => import("../modules/streets/StreetsPage"));
  const DistrictsPage = lazy(
    () => import("../modules/districts/DistrictsPage")
  );
  const ComplaintsPage = lazy(
    () => import("../modules/complaints/ComplaintsPage")
  );
  const ProductPricePage = lazy(
    () => import("../modules/product-prices/ProductPricePage")
  );
  const PaymentTypesPage = lazy(
    () => import("../modules/payment-types/PaymentTypesPage")
  );
  const OrderChannelsPage = lazy(
    () => import("../modules/order-channels/OrderChannelsPage")
  );
  const CategoriesPage = lazy(
    () => import("../modules/categories/CategoriesPage")
  );
  const CampaignsPage = lazy(
    () => import("../modules/campaigns/CampaignsPage")
  );
  const CustomersPage = lazy(
    () => import("../modules/customers/CustomersPage")
  );
  const OrderReportsPage = lazy(
    () => import("../modules/order-reports/OrderReportsPage")
  );
  const CustomerReportsPage = lazy(
    () => import("../modules/customer-reports/CustomerReportsPage")
  );
  const DistributorReportsPage = lazy(
    () => import("../modules/distributor-reports/DistributorReportsPage")
  );
  const CancelNotePages = lazy(
    () => import("../modules/cancel-notes/CancelNotesPage")
  );

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />

        {/* Dashboard */}
        <Route path="dashboard" element={<DashboardWrapper />} />

        {/* Users */}
        <Route
          path="users/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

        {/* Customers */}
        <Route
          path="customers/*"
          element={
            <SuspensedView>
              <CustomersPage />
            </SuspensedView>
          }
        />

        {/* Customers */}
        <Route
          path="zones/*"
          element={
            <SuspensedView>
              <ZoneList />
            </SuspensedView>
          }
        />

        {/* Products */}
        <Route
          path="products/*"
          element={
            <SuspensedView>
              <ProductsPage />
            </SuspensedView>
          }
        />

        {/* Campaigns */}
        <Route
          path="campaigns/*"
          element={
            <SuspensedView>
              <CampaignsPage />
            </SuspensedView>
          }
        />

        {/* Persons */}
        <Route
          path="persons/*"
          element={
            <SuspensedView>
              <PersonsPage />
            </SuspensedView>
          }
        />

        {/* Categories */}
        <Route
          path="categories/*"
          element={
            <SuspensedView>
              <CategoriesPage />
            </SuspensedView>
          }
        />

        {/* Orders */}
        <Route
          path="orders/*"
          element={
            <SuspensedView>
              <OrdersPage />
            </SuspensedView>
          }
        />

        {/* OrderChannels */}
        <Route
          path="order-channels/*"
          element={
            <SuspensedView>
              <OrderChannelsPage />
            </SuspensedView>
          }
        />
        {/* PaymentTypes */}
        <Route
          path="payment-types/*"
          element={
            <SuspensedView>
              <PaymentTypesPage />
            </SuspensedView>
          }
        />
        {/* ProductPrices */}
        <Route
          path="product-prices/*"
          element={
            <SuspensedView>
              <ProductPricePage />
            </SuspensedView>
          }
        />

        {/* ComplaintsPage */}
        <Route
          path="complaints/*"
          element={
            <SuspensedView>
              <ComplaintsPage />
            </SuspensedView>
          }
        />

        {/* Order Reports */}
        <Route
          path="order-reports/*"
          element={
            <SuspensedView>
              <OrderReportsPage />
            </SuspensedView>
          }
        />

        {/* Customer Reports */}
        <Route
          path="customer-reports/*"
          element={
            <SuspensedView>
              <CustomerReportsPage />
            </SuspensedView>
          }
        />

        {/* Distributor Reports */}
        <Route
          path="distributor-reports/*"
          element={
            <SuspensedView>
              <DistributorReportsPage />
            </SuspensedView>
          }
        />

        {/* Cities */}
        <Route
          path="cities/*"
          element={
            <SuspensedView>
              <CitiesPage />
            </SuspensedView>
          }
        />

        {/* Districts */}
        <Route
          path="districts/*"
          element={
            <SuspensedView>
              <DistrictsPage />
            </SuspensedView>
          }
        />

        {/* Neighbourhoods */}
        <Route
          path="neighbourhoods/*"
          element={
            <SuspensedView>
              <NeighbourHoodsPage />
            </SuspensedView>
          }
        />

        {/* Streets */}
        <Route
          path="streets/*"
          element={
            <SuspensedView>
              <StreetsPage />
            </SuspensedView>
          }
        />

        {/* Cancel Notes */}
        <Route
          path="cancel-notes/*"
          element={
            <SuspensedView>
              <CancelNotePages />
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
